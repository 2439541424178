const Gonhou = [
	{
	    path: '/home',
	    tag: "首页",
	    name: "home",
	    component: () => import('@/page/page-Gonhou/pages/home/index.vue'),
	},
	{
	    path: '/allsetList',
	    tag: "全局设置",
	    name: "allsetList",
	    component: () => import('@/page/page-Gonhou/pages/allsetList/index.vue'),
	},
	{
	    path: '/billingList',
	    tag: "手工账单",
	    name: "billingList",
	    component: () => import('@/page/page-Gonhou/pages/billingList/index.vue'),
	},
    {
        path: '/userMake',
        tag: "会员预约",
        name: "userMake",
        component: () => import('@/page/page-Gonhou/pages/userMake/index.vue'),
    },
    {
        path: '/oderList',
        tag: "订单列表",
        name: "oderList",
        component: () => import('@/page/page-Gonhou/pages/oderList/index.vue'),
    },
	
	
	{
	    path: '/bookDetail',
	    tag: "预约明细",
	    name: "bookDetail",
	    component: () => import('@/page/page-Gonhou/pages/bookDetail/index.vue'),
	},
    {
        path: '/basicList',
        tag: "基本信息",
        name: "basicList",
        component: () => import('@/page/page-Gonhou/pages/basicList/index.vue'),
    },
    {
        path: '/sectionList',
        tag: "部门列表",
        name: "sectionList",
        component: () => import('@/page/page-Gonhou/pages/sectionList/index.vue'),
    },
    {
        path: '/clerkAList',
        tag: "员工管理",
        name: "clerkAList",
        component: () => import('@/page/page-Gonhou/pages/clerkAList/index.vue'),
    },

    {
        path: '/clerkBList',
        tag: "门店列表",
        name: "clerkBList",
        component: () => import('@/page/page-Gonhou/pages/clerkBList/index.vue'),
    },
    {
        path:'/venueBList',
        tag: "场馆列表",
        name: "venueBList",
        component: () => import('@/page/page-Gonhou/pages/venueBList/index.vue'),
    },
	{
	    path:'/venueBList-setpage',
	    tag: "场馆场地-高级设置",
	    name: "venueBList-setpage",
	    component: () => import('@/page/page-Gonhou/pages/venueBList/chpage/setpage/index.vue'),
	},
    {
        path: '/monthList',
        tag: "月卡列表",
        name: "monthList",
        component: () => import('@/page/page-Gonhou/pages/monthList/index.vue'),
    },
    {
        path: '/cicarkList',
        tag: "次卡列表",
        name: "cicarkList",
        component: () => import('@/page/page-Gonhou/pages/cicarkList/index.vue'),
    },
	
	{
	    path: '/storedList',
	    tag: "储值卡列表",
	    name: "storedList",
	    component: () => import('@/page/page-Gonhou/pages/storedList/index.vue'),
	},

    {
        path: '/weekList',
        tag: "周卡列表",
        name: "weekList",
        component: () => import('@/page/page-Gonhou/pages/weekList/index.vue'),
    },

    {
        path: '/keceList',
        tag: "课程卡列表",
        name: "keceList",
        component: () => import('@/page/page-Gonhou/pages/keceList/index.vue'),
    },

    {
        path: '/activeList',
        tag: "活动卡列表",
        name: "activeList",
        component: () => import('@/page/page-Gonhou/pages/activeList/index.vue'),
    },
    {
        path: '/privatelist',
        tag: "私家课列表",
        name: "privatelist",
        component: () => import('@/page/page-Gonhou/pages/privatelist/index.vue'),
    },
	{
	    path: '/memberList',
	    tag: "会员卡列表",
	    name: "memberList",
	    component: () => import('@/page/page-Gonhou/pages/memberList/index.vue'),
	},
	{
	    path: '/memberdetails',
	    tag: "会员详情",
	    name: "memberdetails",
	    component: () => import('@/page/page-Gonhou/pages/memberdetails/index.vue'),
	},
	{
	    path: '/mkajuan',
	    tag: "会员卡券",
	    name: "mkajuan",
	    component: () => import('@/page/page-Gonhou/pages/mkajuan/index.vue'),
	},
	{
	    path: '/mchuzhi',
	    tag: "会员卡券",
	    name: "mchuzhi",
	    component: () => import('@/page/page-Gonhou/pages/mchuzhi/index.vue'),
	},
    {
        path: '/memberLesson',
        tag: "团体课列表",
        name: "memberLesson",
        component: () => import('@/page/page-Gonhou/pages/memberLesson/index.vue'),
    },
	{
	    path: '/statementList',
	    tag: "交易流水",
	    name: "statementList",
	    component: () => import('@/page/page-Gonhou/pages/statementList/index.vue'),
	},
	{
	    path: '/stateoderList',
	    tag: "订单列表",
	    name: "stateoderList",
	    component: () => import('@/page/page-Gonhou/pages/stateoderList/index.vue'),
	},
	{
	    path: '/xiaotcList',
	    tag: "销售提成",
	    name: "xiaotcList",
	    component: () => import('@/page/page-Gonhou/pages/xiaotcList/index.vue'),
	},
	
	{
	    path: '/kjiesuList',
	    tag: "跨门店结算",
	    name: "kjiesuList",
	    component: () => import('@/page/page-Gonhou/pages/kjiesuList/index.vue'),
	}
	
]

export default Gonhou