<template>
  <div class="common-items-center" :style='{marginRight:right*1+"px"}'>
      <div class="title" v-if="showBlock">{{title}}</div>
      <el-select v-model="store_id" :size="size" :placeholder="placeholder" :disabled="disabled"  @change="selectVal" :style='{width:width*1+"px",marginLeft:left+"px"}'>
        <el-option
          v-for="item in listdata"
          :key="item.name"
          :label="item.name"
          :value="item.store_id">
        </el-option>
      </el-select>
  </div>
  </template>
  <script>
    export default {
      data() {
        return {
		  listdata:[],
          store_id:"",
        }
      },
      props:{
        showBlock:{
          type: Boolean,
          default:false,
        },
        size:{
          type: String,
          default:"medium",
        },
        keyVal:{
          type: String,
          default: "key",
        },
        value:{
          type: String,
          default: "",
        }, 
        title: {
         type: String,
         default: "",
        },
        width:{
          type: String,
          default: "130",
        },
        left:{
          type: String,
          default: "0",
        },
        right:{
          type: String,
          default: "10",
        },
        disabled:{
          type: Boolean,
          default:false,
        },
        options:{
          type: Array,
          default:()=>([
            { val:0, title: '禁用'}, 
            { val:1, title: '启用'}
          ])
        },
        paddButtom:{
         type: String,
         default: "10",
       },
        placeholder:{
          type: String,
          default: "请选择门店",
        }
      },
	  mounted() {
	  	let list = JSON.parse(localStorage.getItem("MENSTORELIST"))
		console.log(list) 
		this.listdata = list
	  },
      methods:{
        //选取的值
        selectVal(ev){
		   this.$emit("getValBtn",ev)
        },
        setVal(e){
           this.store_id = e 
        }
      }
    }
  </script>
  <style scoped lang="scss">
      .title{
        font-size: 14px;
        color: #606266;
        text-align: right;
        position: relative;
        bottom:1px;
      }
  </style>