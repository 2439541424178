<template>
	<div class="common-flex">
		<div class="blockIng common-relatives" v-for="(item,index) in  url" :key='index'>
			<el-image   :style='{width:width+"px",height:height+"px"}'  :src="item.file_url" fit="cover"></el-image>
			<div class="common-absolute common-top common-right common-center-around deleteBtn" @click="removeBtn(index)">
				<i class="el-icon-delete"></i>
			</div>
		</div>
		<el-upload class="avatar-uploader"
		    v-if="url.length<maxNumber"
		    :action="actionhttp"
			name = "image" 
		    :show-file-list="false"
			:headers='headers'
		    :on-success="uploadSuccess" 
		    :before-upload="beforeAvatarUpload">
			<div class="common-center-around" :style='{width:width+"px",height:height+"px"}'>
				<i  class="el-icon-plus  avatar-uploader-icon" ></i>
			</div>
		</el-upload>
	</div>
    
</template>
<script>
  export default {
    data() {
      return {
          headers:{
			  'Authorization':"",
		  },
		  url:[
			// {
			// 	file_id:27,
            //  file_url:"https://api.wangshikeji.com/uploads/66e4d8b25e7bd.png"
			// }
		  ]
      };
    },
	props:{
		keyval:{
			type:String,
			default:"key",
		},
		listData:{
			type:Object,
			default:()=>{}
		},
		actionhttp:{
			type:String,
			default:"",
		},
		width:{
			type:Number,
			default:100,
		},
		height:{
			type:Number,
			default:100,
		},
		maxNumber:{
			type:Number,
			default:2,
		}
	},
	watch:{
		listData:{
			handler(newVal) {
				// console.log(newVal)
				// if(!!newVal.file_ids){
				// 	let file_ids = newVal.file_ids.split(",")
				// 	let file_url_arr = newVal.file_url_arr
				// 	let url = []
				// 	for(var i in file_ids){
				// 		url.push({
				// 			file_id:file_ids[i],
				// 			file_url:file_url_arr[i]
				// 		})
				// 	}
				// 	this.url = url
				// 	this.getImgUrlFun()
			    // }   
				
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		const token = localStorage.getItem('SUPPLY_SYS_TOKERNDISTRIBUTION')
		this.headers.Authorization = token
	},
    methods: {
      uploadSuccess(res, file) {
		this.url = [...this.url,...[res]]
		this.getImgUrlFun()
      },
      getImgUrlFun(){
		  let ids = []
		  let listid = this.url
		  for(var i  in  listid){
		  	  ids.push(listid[i].file_id)
		  }
		 let parms = {
			key:this.keyval, 
			file_id:ids,
			file_ids:ids.join(","),
			allInfo:this.url
		 }
		this.$emit("getval",parms)  
	  },
	  removeBtn(index){
		 this.url.splice(index,1)
         this.$nextTick(()=>{
			 let ids = []
			 let listid = this.url
			 for(var i  in  listid){
				 ids.push(listid[i].file_id)
			 }
			 let parms = {
				key:this.keyval, 
			 	file_id:ids,
				file_ids:ids.join(","),
			 	allInfo:this.url
			 }
			 this.$emit("getval",parms)
		 })  
	  },
	  setValFun(listA,listB){

		let url = []
		for(var i in listA){
			url.push({
				file_id:listA[i],
				file_url:listB[i]
			})
		}
		this.url = 	url
		this.getImgUrlFun()
	  },  
      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
        return true
      }
    }
  }
</script>
<style lang="less">
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
    }
    .avatar {
      width: 140px;
      height: 140px;
      display: block;
    }
	.blockIng{
		margin-right:5px;
		border-radius:0 4px 0 4px;
		overflow:hidden;
		.deleteBtn{
			width:25px;
			height:25px;
			background-color:red;
			border-radius:0 4px 0 4px;
			.el-icon-delete{
				color:white;
			}
		}
	}
	
</style>