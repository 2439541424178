import Vue from 'vue'
import Vuex from 'vuex'
import {INTER} from "./apiUrl"
import {status_list} from "./text"
Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
		navHeader:60,
        navLeftw:200,
        conPadding:16,
        windoW:0,
        windoH:0,
        status_list:status_list,
        moneyColor:"#FF9800",
    },
    mutations: {
        setWH(state,val) {
            state.windoW = val.width
            state.windoH = val.height
        },
    }
})
export default store