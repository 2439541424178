<template>
  <!--普通表格-->
  <div class="container" :style="{padding:padding}">
    <!--插槽-->
    <slot></slot>
    <div class="warp-table" :style='{marginTop:"12px"}'>
      <el-table :ref="refVal" :data="tableData" empty-text="." size="mini" style="width:100%" row-key="id" lazy
          :height="this.$store.state.windoH - navHeight" @selection-change="checkBtn">
        <template v-for="(item, index) in columns">
          <!--通过render函数返回的自定义-->
          <template v-if="!!item.render">
            <el-table-column v-if="item.isShow ? false : true" :key="index" :prop="item.prop" :label="item.label" :width="item.width" :header-align="item.headerAlign"
              :min-width="item.minWidth" :align="item.align" :fixed="item.fixed">
              <template slot-scope="scope">
                <Custom :render="item.render" :row="scope.row" :index="scope.$index" />
              </template>
            </el-table-column>
          </template>
          <!--纯文本-->
          <template v-else>
            <el-table-column v-if="item.isShow ? false : true" :key="index" :prop="item.prop" :label="item.label" :width="item.width"
              :header-align="item.headerAlign" :min-width="item.minWidth" :align="item.align" :type='item.type'
              :show-overflow-tooltip="item.tooltip" />
          </template>
        </template>
      </el-table>
      <div class="empty" v-if="tableData.length==0">
        <div>
            <img class="icon_empty" src="@/assets/image/icon_empty.png" alt="">
            <div class="text_empty">数据空空</div>
        </div>
      </div>
      <template>
          <BlockPage @getPage="getPage" :total="total" :pageNo="page" :size="size" />
      </template>
    </div>
  </div>
</template>
<script>
import Custom from './custom.vue'
export default {
  name: 'commonTable',
  components: {
    Custom
  },
  data: () => ({
      tableData: [],
      page:1,
      size:20,
      total:1,
  }),
  props: {
    columns: {
      type: Array,
      default: () => []
    },
	padding:{
		type: String,
		default:"0 10px 10px 10px"
	},
    refVal:{
       type: String,
       default: () => ''
    },
    //需要减少的高度
    navHeight: {
      type: Number,
      default: () => 0
    },
    //接口域名
    action: {
      type: String,
      default: () => ''
    },

    //参数
    param: {
      type: Object,
      default: () => ({})
    }
  },

  created() {

  },
  mounted() {

    this.tableData= []
    this.getDatas()
  },
  methods: {

    //获取数据
    getDatas() {
      let parms = {
          page:this.page,
          size:this.size
         }
         parms = {...parms,...this.param}        
      if (!this.action) {
         return
      }
      this.$httpRequest.get(this.action,parms).then(resData => {
          if(resData.code==200){
            this.tableData = resData.data
            this.total = resData.count || resData.data.length
			console.log("shuliang",resData.count)
			let parms = {
				dataNo:resData.data.length,
				total:resData.count
			}
			this.$emit("getNumberBtn",parms)
          }
      })
    },


   emptyFun(params){
      for(var i in params){
          
          if(params[i]=="" || params[i]==null || params[i]==undefined){
          delete params[i]
          }
      }
      return params
    },

    //复选框
    checkBtn(ev) {	
       this.$emit("checkBtn", ev)
    },

    //刷新当前用于更新数据
    RefreshFun() {
       this.getDatas()
    },

    //重置从从第一页开始查询
    resetFun(){
        this.page = 1
        this.tableData = []
        this.getDatas()      
    },

    //分页
    getPage(ev) {
       this.page = ev.pageNo
       this.getDatas()
    }
  }
}
</script>
<style lang="scss" scoped>
.container{
    border-radius:10px;
    overflow: hidden;
    box-sizing:border-box;
    background-color:white;
}
.warp-table {

  position: relative;

  .empty {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index:1200;
    display:flex;
    justify-content:space-around;
    align-items:center;
    .icon_empty {
      width: 100px;
      height: 100px;
      display: block;
    }

    .text_empty {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #84888B;
      padding-bottom:40px;
    }
  }
}

/*全局修改表头*/
::v-deep .el-table__header {
  border-radius: 4px 4px 0 0;
  overflow: hidden;

  th.el-table__cell {
    height: 42px;
    background: #F1F3F4;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .cell {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #66686A;
    }
  }
}

::v-deep .el-table__body .el-table__cell {
  .cell {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    
  }
}

::v-deep .el-button--mini {
  padding: 5px 8px;
}

::v-deep .el-table--mini .el-table__cell {
  padding: 2px 0;
}

::v-deep .cell {
  font-size: 16px;
}
</style>
