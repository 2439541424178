import Vue     from 'vue'
import Router  from 'vue-router'
// import Mallyou from "./../page/page-Mallyou/utlis/route"
import Gonhou from "./../page/page-Gonhou/utlis/route"
Vue.use(Router)
const router =  new Router({
    routes:[
          { 
            path: '/',
            tag:"登录页",
            name: 'Login', 
            component:()=>import('@/page/login.vue')
          },
          {     
            path: '/Gonhou',
            tag:"昆仑体育科技",
            name: 'Index',
            component:()=>import('@/page/page-Gonhou/index.vue'),
            children:Gonhou
          }
    ]   
})
export default router