<template>
    <div class="warp-tree" :style='{ width: width }'>
        <Treeselect 
            ref="select" noChildrenText="没有数据" 
            v-model="val" 
            :disabled="disabled" 
            :multiple="false"
            :options="options" 
            :clearable="false" 
            :normalizer="normalizer" 
             empty-text="没有数据" 
             @select="selectBtn"
            :show-count="true" 
            :placeholder="placeholder"
            >
        </Treeselect>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    components: {
        Treeselect
    },
    
    props: {
        val:{
            type: String,
            default: null,
        },
        keyVal: {
            type: String,
            default: "keyVal",
        },
        width: {
            type: String,
            default: "200px",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "选择上级目录",
        },
        keyId: {
            type: String,
            default: "id",
        },
        labelKey: {
            type: String,
            default: "name",
        },
        childKey: {
            type: String,
            default: "",
        },
        options: {
            type:Array,
            default:()=>[]
        },
    },
    created() {

    },
    mounted() {

    },
    methods: {
        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node[this.keyId],
                label: node[this.labelKey],
                children: node[this.childKey]
            }
        },
        // 获取选中的值
        selectBtn(ev) {
            let parms = {
                key: this.keyVal,
                val: ev,
            }
            this.$emit('getVal', parms);
        },
        //设定选中的值
        setVal(ev) {
            this.val = ev
        }
    }
}
</script>
<style lang="scss" scoped>
.vue-treeselect {
    height: 36px;
    display: flex;
    align-items: center;
}

::v-deep .vue-treeselect__control {
    display: inline-block;
    border: 1px solid #DCDFE6;
    height: 32px !important;
}

::v-deep .vue-treeselect__input-container {
    height: 32px !important;
}

::v-deep .vue-treeselect__single-value {
    line-height: 30px !important;
    font-size: 13px;
    color: #606266 !important;
}

::v-deep .vue-treeselect__placeholder {
    line-height: 32px !important;
}

::v-deep .vue-treeselect__input {
    height: 32px !important;
    vertical-align: top;
}</style>