<template>
  <div class="warpHome">
    <router-view />
  </div>
</template>

<script>
import store from '@/utils/store.js'
export default {
  name: 'App',
  created() {
    setInterval(() => {
      let val = {
        width: window.innerWidth,
        height: window.innerHeight
      }
      store.commit("setWH", val)
    }, 100)
  }
}
</script>

<style lang="less">
.warpHome {
  height: 100vh;
  width: 100vw;
}
img{
  display:block;
}

* {
  padding: 0;
  margin: 0;
}

.common-body {
  width: 1200px;
  margin: 0 auto;
}

.common-noSelect {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.common-items-center {
  display: flex;
  align-items: center;
}

.common-space-around {
  display: flex;
  justify-content: space-around;
}

.common-space-between {
  display: flex;
  justify-content: space-between;
}

.common-textcenter{
  text-align: center;
}


.common-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.common-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.common-flex {
  display: flex;
}

.common-flexw {
  flex: 1;
}

.common-textCenter {
  text-align: center;
}

.common-width {
  width: 100%;
}

.common-hWidth {
  width: 50%;
}

.common-height {
  height: 100%;
}

.common-block {
  display: block;
}

.common-padding {
  padding: 15px;
  box-sizing: border-box;
}

.common-relatives{
  position: relative;
}

.common-absolute {
  position: absolute;
}

.common-top {
  top: 0;
}

.common-bottom {
  bottom: 0;
}

.common-left {
  left: 0;
}

.common-right {
  right: 0;
}

.common-warp-account {
  background: #F5F6F9;
}

.common-closedBtn {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;

  .img {
    display: block;
    cursor: pointer;
  }

  .img:hover {
    transform: scale(1.2);
  }
}

.common-blockContent {
  background: white;
  border-radius: 8px;

}


.clearableBlock {
  position: absolute;
  top: 3px;
  right: 20px;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0);
}

.operateBtn {
  padding: 0 7px !important;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.commonImgShow {
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.iconImg {
  width: 16px;
  height: 16px;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
}

.ellipsis {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imgObject {
  object-fit: cover;
  object-position: center;
}

img {
  display: block;
}


/*图片预览 */
.viewer-close {
  top: 100px !important;
  right: 100px !important;
  width: 40px !important;
  height: 40px !important;
}

.viewer-button::before {
  bottom: 10px !important;
  left: 10px !important;
}

/*
  * 滚动条掉
  */

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #F0F4FC;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #E5EBF8;
}

/*
  * 系统自带
  */

/*全局的input样式*/
.el-input__inner {
  height: 36px !important;
  border: 1px solid #BCBFC3;
}

/*全局按钮的样式*/
.el-button {
  font-size: 13px !important;
}

.el-popover.el-popper.popperClass{
    min-width:100px!important;
}

.el-input__inner:-internal-autofill-selected {
  background-color: #fff !important;
}

.el-form-item {
  margin-bottom:16px !important;
}

.el-form-item__error {
  top: 98% !important;
  transform: scale(0.99);
}

.el-form-item__label {
  padding: 0 14px 0 0 !important;
  position: relative;
}

.el-form-item__label:before {
  margin-top: 2px !important;
}

.el-color-picker__panel {
  padding: 11px !important;
}

.el-color-picker__panel .el-input__inner {
  height: 32px !important;
}

.el-color-picker__panel .el-button--sMaLl {
  padding: 16px 11px !important;
}

.el-color-picker__panel .el-color-dropdown__btns {
  margin-top: 0px !important;
}



</style>
