<template>
    <div>
        <div class="block">
            <div class="titlme" v-if="showTitle">{{title}}</div>
            <el-date-picker v-model="value2" size="small" :clearable="false" type="date" value-format="yyyy-MM-dd" @change="getDate" :editable="false" :placeholder="plStartData" :picker-options="pickerOptions0.pickerOptions1" :style='{ width: width + "px" }' />
            <div v-if="shouEnd">
				<span class="fenge">-</span>
				<el-date-picker v-model="value1" size="small" :clearable="false" type="date" value-format="yyyy-MM-dd" @change="getDate" :editable="false" :placeholder="enStartData" :picker-options="pickerOptions0" :style='{ width: width + "px", marginRight: right + "px" }' />
            </div>
		</div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            value1: '',
            value2: '',
            pickerOptions0: {
                disabledDate: (time) => {
                    if (this.value2 != "") {
                        return time.getTime() < new Date(this.value2).getTime() - 1 * 24 * 60 * 60 * 1000;//减去一天的时间代表可以选择同一天;;
                    }
                },
                pickerOptions1: {
                    disabledDate: (time) => {
                        return time.getTime() > new Date(this.value1).getTime(); 
                    }
                },
            }
        }
    },
    props: {
        right: {
            type: Number,
            default: 10
        },
        title:{
            type: String,
            default: "选择日期"
        },
        showTitle:{
            type:Boolean,
            default:false,
        },
        width: {
            type: Number,
            default: 128
        },
        plStartData:{
            type: String,
            default: "开始日期"
        },
		shouEnd:{
			type:Boolean,
			default:true,
		},
        enStartData:{
            type: String,
            default: "结束日期"
        },
		
    },
    methods: {
        //清空
        clear() {
            this.value2 = ''
            this.value1 = ''
        },
        //获取数据
        getDate() {
            let parms = {
				startDateA:this.value2 || this.value1,
                startDate:this.value2 || this.value1,
                endDate:this.value1 || this.value2,
				endDateB:this.value1 || this.value2
                }
                parms.startDate = parms.startDate+" 00:00:00"
                parms.endDate = parms.endDate+" 23:59:59"

            this.$emit('getDate', parms)
        },
		setvalFun(parms){
			this.value2 = parms.startDate
			this.value1 = parms.endDate
		}
    },
};
</script>
<style lang="scss" scoped>
.block {
    display: flex;
    align-items: center;
}

.titlme {
    font-size: 14px;
    font-size: 14px;
    color: #606266;
    margin-right: 10px;
}

.fenge {
    padding: 0 5px;


}</style>