<template>
		<el-dialog :title="title" :visible.sync="closedblock" :width='width+"px"' :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close='true' @before-close="colesEdBtn">
		    <div class="common-relatives">
				<div class="closedBtn common-absolute" @click="colesEdBtn">
					<i class="el-icon-close"></i>
				</div>
				<slot></slot> 
			</div>
			
			<div class="warpflex" v-if="showBtn" :style='{paddingBottom:bot+"px"}'>
				<el-button @click="colesEdBtn">取 消</el-button>
				<el-button type="primary" @click="sureBtn">确 定</el-button>
			</div>
		</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
	            closedblock:true
			};
		},
	    props:{
			title:{
				type:String,
				default:"标题"
			},
			bot:{
				type:Number,
				default:10
			},
			width:{
				type:Number,
				default:180
			},
			showBtn:{
				type:Boolean,
				default:false
			}
		},
		watch:{
			
		},
		methods: {
			//关闭
			colesEdBtn(){
				this.$emit("closedBtn")
			},
			//确定
			sureBtn(){
				this.$emit("sureBtn")
			}
		}
	};
</script>
<style>
	.el-dialog{
		border-radius:10px;
		overflow: hidden;
	}
	.el-dialog__header{
		border-bottom:1px solid rgba(0,0,0,0.09)!important;
		padding: 15px!important;
		box-sizing: border-box;
		/* background-color:red; */
		
	}
	.el-dialog__body{
		padding: 20px 20px 10px 20px!important;
		box-sizing: border-box;
	}
	.el-dialog__headerbtn{
		font-size:18px!important;
		top:19px;
	}
	.warpflex{
		display: flex;
		justify-content: flex-end;
	}
	.el-icon-close{
		font-size:24px;
	}
	.closedBtn{
		top:-56px;
		right:0;
		z-index:1234;
	}
	
</style>