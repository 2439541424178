// src/services/httpService.js
import axios from 'axios';
import router from './route'; // 引入Vue Router实例
import { Message } from 'element-ui';
import Vue from 'vue'

axios.defaults.baseURL = '你的API基础URL';
axios.defaults.timeout = 5000;

// 请求拦截器，添加token
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('SUPPLY_SYS_TOKERNDISTRIBUTION'); // 从localStorage中获取token
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器，处理未授权
axios.interceptors.response.use(response => response, error => {
  console.log("huoq",error)	
  
  
  
  if(error.code=="ERR_BAD_RESPONSE"){
	 Vue.prototype.$message({
		 message: error.code,
		 type: 'warning'
	 }); 
  }else{
	 //  const { status } = error.response;
	 //  if (status === 401 && !error.config.url.endsWith('/')) { // 排除登录请求
		// // localStorage.removeItem('SUPPLY_SYS_TOKERNDISTRIBUTION');                   // 清除本地存储的token
		// // router.replace('/');                                   // 重定向到登录页
	 //  }
    // if(){

    // }
	  
  }
  return Promise.reject(error);
});

// 封装请求方法
const httpService = {
  get(url, params) {
    return makeRequest({ url, method: 'get', params });
  },
  post(url, data) {
    return makeRequest({ url, method: 'post', data });
  },
  put(url, data) {
    return makeRequest({ url, method: 'put', data });
  },
  delete(url) {
    return makeRequest({ url, method: 'delete' });
  },
};

function emptyFun(params){
  for(var i in params){
      
      if(params[i]=="" || params[i]==null || params[i]==undefined){
       delete params[i]
      }
  }
  return params
}

// 统一的请求处理函数，便于错误处理和日志记录
function makeRequest(config) {
  return new Promise((resolve, reject) => {
    axios(config).then(response => {
		console.log(!!response)
	   if(!!response){
		 let { data } = response
		 if (data.code == 200) {
		   resolve(response.data)
		 } else {
		   console.log(response)  		
		   Message.error(data.message)
		 }  
	   }else{
		  // alert("网络异常") 
		  //  Vue.$message({
			 // message: '网络异常',
			 // type: 'warning'
		  //  });  
	   }
      }).catch(error => {
        // console.error(`[${config.method}] Request failed for ${config.url}`, error);
        reject(error);
      });
  });
}

export default httpService;